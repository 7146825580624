import './CloudInteroperability.scss';
import React from 'react';
import Typography from '@components/Typography';
import Container from '@components/Container';
import Interoperability from '../../../common/Interoperability';

const CloudInteroperability: React.FC = () => {
  return (
    <div className="TDB-CloudPage__Interoperability">
      <Interoperability
        title="Use your favorite tools"
        description="TileDB Cloud interoperates with your existing workflows, with numerous APIs, support for popular machine learning frameworks, and domain-specific integrations."
      />
      <Container>
        <div className="TDB-CloudPage__Interoperability__Ribbon">
          <Typography fontWeight="semi-bold" fontSize="subheading-1" color="neutral-main" align="center" as="p">
            Efficient APIs and tool integrations with <span className="TDB-CloudPage__Interoperability__Ribbon__Emphasis">TileDB</span> via
            zero-copy techniques
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default CloudInteroperability;
