import './OneSolution.scss';
import React, { useMemo, useRef, useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Container from '@components/Container';
import Typography from '@components/Typography';
import InlineButton from '@components/InlineButton';
import links from '@constants/links';
import minNumber from '@utils/helpers/minNumber';
import Graphic from './assets/multicube.png';
import cubeTop from './assets/top_cube.png';
import cubeBottom from './assets/bottom_cube.png';

declare const window: any;

const OneSolution: React.FC = () => {
  const scrollElementRef = useRef<any>();
  const [scrollTop, setScrollTop] = useState(0);

  useScrollPosition(
    ({ currPos }) => {
      setScrollTop(currPos.y);
    },
    [],
    scrollElementRef,
    undefined
  );

  const threshold = useMemo(() => {
    const halfScreenHeight = typeof window === 'undefined' ? 0 : window.outerHeight / 2;
    const windowWidth = typeof window === 'undefined' ? 0 : window.outerWidth;
    let t = 0; // default scrollTop

    if (typeof window === 'undefined' || window.innerWidth > 1024) {
      // don't move things in desktop view
      t = 0;
    } else {
      // calculate top margins
      t = scrollTop - halfScreenHeight + windowWidth / 4;
    }

    // scrollTop should not be negative
    return minNumber(t, 0);
  }, [scrollTop]);

  return (
    <Container className="TDB-CloudPage__OneSolutionWrapper">
      <div className="TDB-CloudPage__OneSolution">
        <div className="TDB-CloudPage__OneSolution__Section">
          <Typography
            as="p"
            className="TDB-CloudPage__OneSolution__Section__Title"
            fontWeight="bold"
            color="neutral-600"
            fontSize="overline"
          >
            UNIVERSAL
          </Typography>
          <Typography as="p" className="TDB-CloudPage__OneSolution__Section__Subtitle" fontWeight="bold" fontSize="heading-1">
            <span className="TDB-CloudPage__OneSolution__Section__Subtitle--accent">One</span> solution for all data
          </Typography>
          <Typography
            className="TDB-CloudPage__OneSolution__Section__Paragraph"
            as="p"
            fontSize="body-lg"
            fontWeight="medium"
            color="neutral-600"
          >
            TileDB Cloud employs a modern database design that optimizes the analysis of all data types, for all applications. Manage
            tables, images, video, genomics, ML features, metadata, even flat files and folders — all in a single powerful solution based on
            multi-dimensional arrays.
          </Typography>
          <img className="TDB-CloudPage__OneSolution__MobileCube TDB-CloudPage__OneSolution__MobileCube--top" src={cubeTop} alt="" />
        </div>
        <div
          className="TDB-CloudPage__OneSolution__Section TDB-CloudPage__OneSolution__Section--blue"
          ref={scrollElementRef}
          style={{ top: threshold / 4 }}
        >
          <img className="TDB-CloudPage__OneSolution__MobileCube TDB-CloudPage__OneSolution__MobileCube--bottom" src={cubeBottom} alt="" />
          <div className="TDB-CloudPage__OneSolution__MobileTriangle" />
          <Typography
            as="p"
            className="TDB-CloudPage__OneSolution__Section__Title"
            fontWeight="bold"
            color="neutral-600"
            fontSize="overline"
            style={{ paddingTop: threshold }}
          >
            PERFORMANT
          </Typography>
          <Typography as="p" className="TDB-CloudPage__OneSolution__Section__Subtitle" fontWeight="bold" fontSize="heading-1">
            <span className="TDB-CloudPage__OneSolution__Section__Subtitle--accent">Everything</span> is an array
          </Typography>
          <Typography
            className="TDB-CloudPage__OneSolution__Section__Paragraph"
            as="p"
            fontSize="body-lg"
            fontWeight="medium"
            color="neutral-600"
          >
            TileDB Cloud uses the open-source TileDB Embedded storage engine, which stores all data as multi-dimensional arrays, the
            currency of data analytics. This canonical array format adapts to data of any shape and size, enabling superior performance
            tailored to any application workload.
          </Typography>
          <InlineButton to={links.embedded}>Learn more</InlineButton>
        </div>
        <img className="TDB-CloudPage__OneSolution__DesktopGraphic" src={Graphic} alt="cube" />
      </div>
    </Container>
  );
};

export default OneSolution;
