import isNumber from '../isNumber';

/**
 * Helper function that limits a number to a minimum
 * @param input the input number
 * @param min the minimum possible number
 * @returns number
 */
const minNumber = (input: number, min: number) => {
  if (!isNumber(input) || !isNumber(min) || input >= min) {
    return input;
  }

  return min;
};

export default minNumber;
