import React from 'react';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts';
import CloudInAction from '@components/CloudInAction';
import links from '@constants/links';
import Governance from './Governance';
import Hero from './Hero';
import CloudInteroperability from './CloudInteroperability';
import Notebooks from './Notebooks';
import OneSolution from './OneSolution';
import Versioning from './Versioning';
import AssetTypes from './AssetTypes';
import ShareAndMonetization from './ShareAndMonetization';
import Offerings from './Offerings';
import shareImage from './assets/TileDB_Cloud_capabilities_page_thumbnail.jpg';

const CloudPage = () => {
  return (
    <IndexLayout
      pageName="cloud"
      helmet={{
        title: 'TileDB Cloud',
        description:
          'Manage, collaborate, scale. Consolidate the modern data stack with one platform for all modalities, code and compute.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="white" />
      <main className="main">
        <Hero />
        <OneSolution />
        <Versioning />
        <Governance />
        <CloudInteroperability />
        <Notebooks />
        <AssetTypes />
        <ShareAndMonetization />
        <Offerings />
        <CloudInAction cta={{ text: 'Sign up', href: links.console.signup }} />
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default CloudPage;
