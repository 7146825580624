import './Notebooks.scss';
import React, { createElement, useState } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import Container from '@components/Container';
import Typography from '@components/Typography';
import sideGraphLeft from './assets/side-graph-left.svg';
import sideGraphRight from './assets/side-graph-right.svg';
import notebook from './assets/notebook.svg';
import cube from './assets/cube.inline.svg';
import launch from './assets/launch.inline.svg';
import dashboard from './assets/dashboard.inline.svg';
import jupyterLogo from './assets/jupyter.svg';
import createGraphic from './assets/create_graphic.png';
import launchGraphic from './assets/launch_graphic.png';
import sharingGraphic from './assets/sharing_graphic.png';

const sidebarContent = {
  create: {
    icon: cube,
    title: 'Create notebooks',
    text: 'Dive right into analyses using pre-configured notebook images loaded with popular packages for data science, genomics and geospatial applications. You can also configure the Jupyter environment manually to run custom packages and tools on TileDB Cloud.',
    graphic: createGraphic,
  },
  launch: {
    icon: launch,
    title: 'Launch serverlessly',
    text: 'There are no servers to manage on TileDB Cloud. Notebooks launch serverlessly from the TileDB Cloud console. Refine your code, and then connect to serverless compute resources and parallelize large computations using UDFs and task graphs.',
    graphic: launchGraphic,
  },
  sharing: {
    icon: dashboard,
    title: 'Jumpstart sharing & collaboration',
    text: 'Collaborate on Jupyter notebooks, without having to move large datasets. Sharing is scoped tightly to individual data and code assets, with access controls and logging capabilities. Versioning lets you track progress with collaborators and roll back notebook changes if necessary.',
    graphic: sharingGraphic,
  },
};

type SectionType = 'create' | 'launch' | 'sharing';

interface SidebarContentProps {
  section: SectionType;
  activeSection: SectionType;
  onClick: (activeSection: SectionType) => void;
}

const SidebarContent: React.FC<SidebarContentProps> = (props) => {
  const { section, activeSection, onClick } = props;

  const { icon, title, text } = sidebarContent[section];
  const active = activeSection === section;

  return (
    <div className="TDB-CloudPage__Notebooks__SidebarContent">
      <button
        className={classNames('TDB-CloudPage__Notebooks__SidebarContent__Title', {
          'TDB-CloudPage__Notebooks__SidebarContent__Title--active': active,
        })}
        onClick={() => onClick(section)}
      >
        {createElement(icon)}
        <Typography as="p" fontSize="heading-4" fontWeight="bold" color="neutral-main">
          {title}
        </Typography>
      </button>
      <AnimatePresence>
        {active && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="TDB-CloudPage__Notebooks__SidebarContent__Paragraph">
              <Typography as="p" fontSize="body-lg" fontWeight="medium" color="neutral-main">
                {text}
              </Typography>
            </div>
            <div className="TDB-CloudPage__Notebooks__Graphic--mobile">
              <img src={sidebarContent[activeSection].graphic} alt="" />
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

const Notebooks: React.FC = () => {
  const [activeSection, setActiveSection] = useState<SectionType>('create');

  return (
    <div className="TDB-CloudPage__NotebookSection">
      <div className="TDB-CloudPage__NotebookSection__JupyterLogo">
        <img src={jupyterLogo} alt="jupyter" />
      </div>
      <img
        className="TDB-CloudPage__NotebookSection__SideGraph TDB-CloudPage__NotebookSection__SideGraph--left"
        src={sideGraphLeft}
        alt="graphic"
      />
      <img
        className="TDB-CloudPage__NotebookSection__SideGraph TDB-CloudPage__NotebookSection__SideGraph--right"
        src={sideGraphRight}
        alt="graphic"
      />
      <Container>
        <div className="TDB-CloudPage__Notebooks">
          <Typography className="TDB-CloudPage__Notebooks__Heading" fontSize="heading-4" fontWeight="bold" as="h3" align="center">
            <img src={notebook} alt="notebook" />
            Jupyter Notebooks
          </Typography>
          <Typography
            as="p"
            className="TDB-CloudPage__Notebooks__Title"
            fontSize="heading-1"
            fontWeight="bold"
            color="neutral-main"
            align="center"
          >
            Create, launch and share notebooks on TileDB Cloud
          </Typography>

          <div className="TDB-CloudPage__Notebooks__Content">
            <div className="TDB-CloudPage__Notebooks__Text">
              <AnimatePresence>
                <SidebarContent section="create" activeSection={activeSection} onClick={setActiveSection} />
                <SidebarContent section="launch" activeSection={activeSection} onClick={setActiveSection} />
                <SidebarContent section="sharing" activeSection={activeSection} onClick={setActiveSection} />
              </AnimatePresence>
            </div>
            <div className="TDB-CloudPage__Notebooks__Graphic--desktop">
              <AnimatePresence>
                <motion.img
                  key={sidebarContent[activeSection].graphic}
                  src={sidebarContent[activeSection].graphic}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                />
              </AnimatePresence>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Notebooks;
