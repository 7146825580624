import './AssetTypes.scss';
import React from 'react';
import Typography from '@components/Typography';
import links from '@constants/links';
import ml from './assets/ml.svg';
import graph from './assets/graph.svg';
import dashboard from './assets/dashboard.svg';
import udf from './assets/udf.svg';
import mlModelsGraphic from './assets/ML_models.png';
import dataVisualizationGraphic from './assets/data_visualization.png';
import udfsGraphic from './assets/UDFs.png';
import taskGraphsGraphic from './assets/task_graphs.png';

interface AssetTypeProps {
  title: string;
  description: string;
  paragraph: string;
  icon: string;
  link: string;
  graphic: string;
}

const AssetType: React.FC<AssetTypeProps> = (props) => {
  const { title, description, paragraph, icon, link, graphic } = props;

  return (
    <div className="TDB-CloudPage__AssetType">
      <div className="TDB-CloudPage__AssetType__Content">
        <div className="TDB-CloudPage__AssetType__Heading">
          <img src={icon} alt={title} />
          <Typography as="p" fontSize="heading-4" color="neutral-800" fontWeight="bold">
            {title}
          </Typography>
        </div>
        <Typography className="TDB-CloudPage__AssetType__Description" as="p" fontSize="heading-2" color="neutral-800" fontWeight="bold">
          {description}
        </Typography>
        <Typography className="TDB-CloudPage__AssetType__Paragraph" as="p" color="neutral-900" fontWeight="medium">
          {paragraph}
        </Typography>
        <img src={graphic} alt={title} className="TDB-CloudPage__AssetType__Graphic" />
      </div>
    </div>
  );
};

const AssetTypes: React.FC = () => {
  return (
    <div className="TDB-CloudPage__AssetTypes">
      <AssetType
        icon={ml}
        title="ML Models"
        description="Build and scale ML models"
        paragraph="Centrally manage ML models alongside the data and code used to train them. TileDB Cloud automatically versions models for efficient collaboration and exposes metadata directly for fast exploration and search. Share and scale your work with support for popular frameworks like PyTorch, Tensorflow, Keras and Scikit-Learn."
        link={links.docs}
        graphic={mlModelsGraphic}
      />
      <AssetType
        icon={dashboard}
        title="Dashboards"
        description="Visualize your data"
        paragraph="Expose your work to a wider audience. The same notebooks that power your research workflows can publish data visualizations to the web as interactive dashboards. Dashboards are a dedicated asset type in TileDB Cloud and are shareable from the TileDB Cloud console."
        link={links.docs}
        graphic={dataVisualizationGraphic}
      />
      <AssetType
        icon={udf}
        title="Scalable Compute"
        description="Serverless SQL & UDFs"
        paragraph="Compute on large datasets, without expensive clusters. TileDB Cloud moves computations closer to your data, automatically scaling based on code execution and minimizing egress by reducing the size of results. Aggregation queries are especially effective, along with compute push-down into the storage engine. TileDB Cloud supports serverless SQL and serverless UDFs in Python and R."
        link={links.docs}
        graphic={udfsGraphic}
      />
      <AssetType
        icon={graph}
        title="Task Graphs"
        description="Parallelize execution"
        paragraph="Combine basic actions, like slicing and SQL, with UDFs to build any distributed algorithm. Easily order complex operations as a directed task graph. Programmatically dispatch task graphs, or use TileDB Cloud’s Jupyter environment to create and execute them. TileDB Cloud parallelizes execution in a serverless manner, while respecting the dependencies at each stage and monitoring all progress."
        link={links.docs}
        graphic={taskGraphsGraphic}
      />
    </div>
  );
};

export default AssetTypes;
