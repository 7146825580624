import './ShareAndMonetization.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import curve from './assets/curve.svg';
import globe from './assets/globe.svg';
import dollar from './assets/dollar.svg';
import sharing from './assets/sharing.png';
import monetization from './assets/monetization.png';

const ShareAndMonetization: React.FC = () => {
  return (
    <div className="TDB-CloudPage__ShareAndMonetizationWrapper">
      <div className="TDB-CloudPage__ShareAndMonetizationBackground">
        <Container>
          <div className="TDB-CloudPage__ShareAndMonetization">
            <div className="TDB-CloudPage__ShareAndMonetization__Block TDB-CloudPage__ShareAndMonetization__Block--sharing">
              <div className="TDB-CloudPage__ShareAndMonetization__Block__Content">
                <img className="TDB-CloudPage__ShareAndMonetization__Icon" src={globe} alt="globe" />
                <div className="TDB-CloudPage__ShareAndMonetization__Block__Text">
                  <Typography
                    className="TDB-CloudPage__ShareAndMonetization__Title"
                    as="h4"
                    fontSize="display-2"
                    color="brand-100"
                    fontWeight="bold"
                  >
                    Secure sharing
                  </Typography>
                  <Typography
                    className="TDB-CloudPage__ShareAndMonetization__Subtitle"
                    as="p"
                    fontSize="heading-3"
                    color="neutral-main"
                    fontWeight="bold"
                  >
                    Discover, contribute & collaborate
                  </Typography>
                  <Typography
                    className="TDB-CloudPage__ShareAndMonetization__Paragraph"
                    as="p"
                    color="brand-100"
                    fontWeight="medium"
                    fontSize="body-lg"
                  >
                    Automatically catalog datasets, code, and associated metadata for advanced discoverability and governance. TileDB Cloud
                    offers access controls so you can easily grant and manage permissions. Version your work, including notebooks and ML
                    models, and securely share them with internal teams and external partners.
                  </Typography>
                </div>
              </div>
              <div className="TDB-CloudPage__ShareAndMonetization__Block__Graphic TDB-CloudPage__ShareAndMonetization__Block__Graphic--globe">
                <img src={sharing} alt="sharing" />
              </div>
            </div>
            <div className="TDB-CloudPage__ShareAndMonetization__Block TDB-CloudPage__ShareAndMonetization__Block--monetization">
              <div className="TDB-CloudPage__ShareAndMonetization__Block__Content">
                <img className="TDB-CloudPage__ShareAndMonetization__Icon" src={dollar} alt="dollar" />
                <div className="TDB-CloudPage__ShareAndMonetization__Block__Text">
                  <Typography
                    className="TDB-CloudPage__ShareAndMonetization__Title"
                    as="h4"
                    fontSize="display-2"
                    color="brand-100"
                    fontWeight="bold"
                  >
                    Monetization
                  </Typography>
                  <Typography
                    className="TDB-CloudPage__ShareAndMonetization__Subtitle"
                    as="p"
                    fontSize="heading-3"
                    color="neutral-main"
                    fontWeight="bold"
                  >
                    Discover, contribute & collaborate
                  </Typography>
                  <Typography
                    className="TDB-CloudPage__ShareAndMonetization__Paragraph"
                    as="p"
                    color="brand-100"
                    fontWeight="medium"
                    fontSize="body-lg"
                  >
                    Sell proprietary data, code and more, with no additional infrastructure or cost. Built-in payment and marketplace
                    features allow you to develop derived data products and monetize them in a new economic model that accommodates data
                    producers and consumers alike.
                  </Typography>
                </div>
              </div>
              <div className="TDB-CloudPage__ShareAndMonetization__Block__Graphic">
                <img src={monetization} alt="monetization" />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <img src={curve} alt="" className="TDB-CloudPage__ShareAndMonetization__Curve" />
    </div>
  );
};

export default ShareAndMonetization;
