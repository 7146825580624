import './Governance.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import GraphicDesktop from './assets/graphic_desktop.png';
import GraphicMobile from './assets/graphic_mobile.png';
import Switch from './assets/switch.svg';

const Governance: React.FC = () => {
  return (
    <Container>
      <div className="TDB-CloudPage__Governance">
        <div className="TDB-CloudPage__Governance__Text">
          <div className="TDB-CloudPage__Governance__Header">
            <img src={Switch} alt="" />
            <Typography className="TDB-CloudPage__Governance__Header__Text" fontWeight="bold" color="brand-900" fontSize="overline">
              CONTROLLED
            </Typography>
          </div>
          <Typography as="p" className="TDB-CloudPage__Governance__Title" fontSize="heading-1" fontWeight="bold">
            <span className="TDB-CloudPage__Governance__Title--accent">Secure</span> governance
          </Typography>
          <Typography className="TDB-CloudPage__Governance__Paragraph" as="p" fontWeight="medium" fontSize="body-lg" color="neutral-600">
            Control and monitor all access. Define access controls and securely share any data asset. Centralize permissions management and
            billing using TileDB Cloud organizations. Hierarchically organize assets into TileDB groups to simplify collaboration on large
            projects. All access and compute are logged for detailed data governance and compliance.
          </Typography>
        </div>
        <div className="TDB-CloudPage__Governance__Graphic">
          <img className="TDB-CloudPage__Governance__Graphic--desktop" src={GraphicDesktop} alt="" />
          <img className="TDB-CloudPage__Governance__Graphic--mobile" src={GraphicMobile} alt="" />
        </div>
      </div>
    </Container>
  );
};

export default Governance;
