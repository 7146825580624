import './Hero.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import Button from '@components/Button';
import links from '@constants/links';
import graphic from './assets/Graphic.jpg';

const Hero: React.FC = () => {
  return (
    <div className="TDB-CloudPage__Hero">
      <Container>
        <div className="TDB-CloudPage__Hero__Text">
          <Typography color="neutral-600" as="h1" className="TDB-CloudPage__Hero__Overline" fontWeight="bold" fontSize="overline">
            TILEDB <span className="TDB-CloudPage__Hero__Overline--accent">CLOUD</span>
          </Typography>
          <Typography as="h2" className="TDB-CloudPage__Hero__Display" fontSize="display-1" fontWeight="bold" color="neutral-800">
            The ultimate data platform
          </Typography>
          <Typography as="p" className="TDB-CloudPage__Hero__Paragraph" fontSize="heading-5" color="neutral-600" fontWeight="medium">
            Manage, collaborate, scale.
            <div style={{ marginTop: 16, maxWidth: 600 }}>
              Consolidate the modern data stack with one platform for all modalities, code and compute.
            </div>
          </Typography>
          <Button primary className="TDB-CloudPage__Hero__ButtonGetStarted" href={links.console.signup}>
            Get started <span> - for free</span>
          </Button>
        </div>
        <div className="TDB-CloudPage__Hero__Graphic">
          <img src={graphic} alt="" />
          <div className="TDB-CloudPage__Hero__Graphic__Gradient" />
        </div>
      </Container>
    </div>
  );
};

export default Hero;
