import './Versioning.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import Graphic from './assets/versioned.png';
import v4 from './assets/v4.svg';
import v3 from './assets/v3.svg';
import v2 from './assets/v2.svg';
import v1 from './assets/v1.svg';

const Versioning = () => {
  return (
    <Container>
      <div className="TDB-CloudPage__Versioning">
        <div className="TDB-CloudPage__Versioning__DummyCard">
          <img src={v1} alt="" className="TDB-CloudPage__Versioning__VersionIndicator" />
        </div>
        <div className="TDB-CloudPage__Versioning__DummyCard">
          <img src={v2} alt="" className="TDB-CloudPage__Versioning__VersionIndicator" />
        </div>
        <div className="TDB-CloudPage__Versioning__DummyCard">
          <img src={v3} alt="" className="TDB-CloudPage__Versioning__VersionIndicator" />
        </div>
        <div className="TDB-CloudPage__Versioning__Card">
          <Typography
            className="TDB-CloudPage__Versioning__Card__Versioned"
            color="neutral-600"
            align="center"
            fontWeight="bold"
            fontSize="overline"
            as="p"
          >
            VERSIONED
          </Typography>
          <div className="TDB-CloudPage__Versioning__Text">
            <Typography as="p" className="TDB-CloudPage__Versioning__Title" fontSize="heading-1" fontWeight="bold">
              Version all data assets and <span className="TDB-CloudPage__Versioning__Title--accent">time travel</span>
            </Typography>
            <Typography
              className="TDB-CloudPage__Versioning__Description"
              as="p"
              fontWeight="medium"
              fontSize="body-lg"
              color="neutral-600"
            >
              Get data versioning and time-traveling out of the box. Versioning capabilities are built into the TileDB array format itself,
              allowing all data and code assets on TileDB Cloud to inherit these properties. Quickly browse different versions of your
              assets in the TileDB Cloud console, or access them programmatically in the language of your choice.
            </Typography>
          </div>
          <div className="TDB-CloudPage__Versioning__Graphic">
            <img src={Graphic} alt="" />
          </div>
          <img src={v4} alt="" className="TDB-CloudPage__Versioning__VersionIndicator" />
        </div>
      </div>
    </Container>
  );
};

export default Versioning;
