import './Offerings.scss';
import React, { ReactNode } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Container from '@components/Container';
import Typography from '@components/Typography';
import Button from '@components/Button';
import links from '@constants/links';

interface CardProps {
  heading: string;
  title: ReactNode;
  link: string;
  buttonText: string;
}

const Card: React.FC<CardProps> = (props) => {
  const { heading, title, link, buttonText } = props;

  return (
    <div className="TDB-CloudPage__Offerings__Card">
      <div className="TDB-CloudPage__Offerings__Card__Pattern" />
      <div className="TDB-CloudPage__Offerings__Card__Content">
        <div className="TDB-CloudPage__Offerings__Card__Text">
          <Typography as="p" className="TDB-CloudPage__Offerings__Card__Heading" fontSize="overline" fontWeight="bold" color="neutral-main">
            <span className="TDB-CloudPage__Offerings__Card__Heading--accent">{heading}</span>
          </Typography>
          <Typography as="p" className="TDB-CloudPage__Offerings__Card__Title" color="neutral-main" fontSize="heading-2" fontWeight="bold">
            {title}
          </Typography>
        </div>
        <AnchorLink to={link}>
          <Button arrow primary>
            {buttonText}
          </Button>
        </AnchorLink>
      </div>
    </div>
  );
};

const Offerings: React.FC = () => {
  return (
    <Container>
      <div className="TDB-CloudPage__Offerings">
        <Typography className="TDB-CloudPage__Offerings__Heading" fontWeight="bold" fontSize="overline" color="brand-900">
          VERSATILE
        </Typography>
        <Typography className="TDB-CloudPage__Offerings__Title" fontWeight="bold" fontSize="heading-1" color="neutral-900">
          Deployment options
        </Typography>
        <div className="TDB-CloudPage__Offerings__Cards">
          <Card
            heading="FOR INDIVIDUALS"
            title="Flexible pay-as-you-go  SaaS"
            link={`${links.pricing}#individuals-plans`}
            buttonText="Individuals pricing"
          />
          <Card
            heading="FOR ORGANIZATIONS"
            title="Either SaaS or on-prem"
            link={`${links.pricing}#organization-plans`}
            buttonText="Organization pricing"
          />
        </div>
      </div>
    </Container>
  );
};

export default Offerings;
